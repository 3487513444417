import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSentiment, TValidation } from 'src/models/general';
import { ILabelArticle, IAdvanceSearchOnm } from 'src/models/onlineMedia';

export const initialState: IAdvanceSearchOnm = {
  sentiment: [],
  validationList: [],
  mediaList: [],
  includeKeywords: [],
  excludeKeywords: [],
  searchType: 'allSearch',
  labelList: [],
  excludeMediaList: [],
  queryStringBooleanOperator: 'and',
  tier: []
};

export const advanceSearchOnm = createSlice({
  name: 'advanceSearchOnm',
  initialState,
  reducers: {
    reducerUpdateAdvanceSearchOnmSentiment: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<Array<TSentiment>>
    ) => {
      state.sentiment = action.payload;
    },
    reducerUpdateAdvanceSearchOnmValidationList: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<Array<TValidation>>
    ) => {
      state.validationList = action.payload;
    },
    reducerUpdateAdvanceSearchOnmLabelList: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<Array<string>>
    ) => {
      state.labelList = action.payload;
    },
    reducerUpdateAdvanceSearchOnmMediaList: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<Array<string>>
    ) => {
      state.mediaList = action.payload;
    },
    reducerUpdateAdvanceSearchOnmIncludeKeywords: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<Array<string>>
    ) => {
      state.includeKeywords = action.payload;
    },
    reducerUpdateAdvanceSearchOnmExcludeKeywords: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<Array<string>>
    ) => {
      state.excludeKeywords = action.payload;
    },
    reducerUpdateAdvanceSearchOnmExcludeMediaList: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<Array<string>>
    ) => {
      state.excludeMediaList = action.payload;
    },
    reducerUpdateAdvanceSearchOnm: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<IAdvanceSearchOnm>
    ) => {
      state.mediaList = action.payload.mediaList;
      state.searchType = action.payload.searchType;
      state.includeKeywords = action.payload.includeKeywords;
      state.excludeKeywords = action.payload.excludeKeywords;
      state.sentiment = action.payload.sentiment;
      state.validationList = action.payload.validationList;
      state.labelList = action.payload.labelList;
      state.excludeMediaList = action.payload.excludeMediaList;
      state.queryStringBooleanOperator =
        action.payload.queryStringBooleanOperator;
      state.tier = action.payload.tier;
    },
    reducerClearAdvanceSearchOnm: (
      state: IAdvanceSearchOnm,
      action: PayloadAction<IAdvanceSearchOnm>
    ) => {
      state.sentiment = action.payload.sentiment;
      state.validationList = action.payload.validationList;
      state.mediaList = action.payload.mediaList;
      state.includeKeywords = action.payload.includeKeywords;
      state.excludeKeywords = action.payload.excludeKeywords;
      state.searchType = action.payload.searchType;
      state.labelList = action.payload.labelList;
      state.excludeMediaList = action.payload.excludeMediaList;
      state.queryStringBooleanOperator =
        action.payload.queryStringBooleanOperator;
      state.tier = action.payload.tier;
    }
  }
});

export const {
  reducerUpdateAdvanceSearchOnmSentiment,
  reducerUpdateAdvanceSearchOnmExcludeKeywords,
  reducerUpdateAdvanceSearchOnmIncludeKeywords,
  reducerUpdateAdvanceSearchOnmLabelList,
  reducerUpdateAdvanceSearchOnmValidationList,
  reducerUpdateAdvanceSearchOnm,
  reducerUpdateAdvanceSearchOnmMediaList,
  reducerClearAdvanceSearchOnm,
  reducerUpdateAdvanceSearchOnmExcludeMediaList
} = advanceSearchOnm.actions;

export default advanceSearchOnm.reducer;
